
import Vue from 'vue'
import SaIcon from '~/components/_general/SaIcon.vue'

export default Vue.extend({
  name: 'SiteCarouselArrow',
  components: { SaIcon },
  props: {
    right: {
      type: Boolean,
    },
    large: {
      type: Boolean,
    },
  },
})
