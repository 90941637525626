
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { featureLink, FeatureLink } from '~/assets/ts/utils/urls'
import SaLinkOrHref from '~/components/_general/SaLinkOrHref.vue'
import LoadingElement from '~/components/_general/LoadingElement.vue'
import SaImage from '~/components/_general/SaImage.vue'
import SiteFeaturedSpotlightDots from '~/components/site/featured/SpotlightDots.vue'
import Countdown from '~/components/_general/Countdown.vue'
import SiteTextFeatureBorder from '~/components/site/TextFeatureBorder.vue'
import { DisplayAdApi } from '~/apiclient/apiads'
import { randomInArray } from '~/assets/ts/utils/lists'
import SiteCarouselArrow from '~/components/site/CarouselArrow.vue'

export default Vue.extend({
  name: 'SiteFeaturedSpotlightContent',
  components: {
    SiteCarouselArrow,
    SiteTextFeatureBorder,
    Countdown,
    SiteFeaturedSpotlightDots,
    SaImage,
    LoadingElement,
    SaLinkOrHref,
  },
  props: {
    features: {
      type: Array as PropType<DisplayAdApi[]>,
      default: () => [],
    },
  },
  data() {
    return {
      activeIndex: 0,
      countdownMs: 0,
    }
  },
  computed: {
    unitedPrayer(): boolean {
      return this.feature?.campaignID === 'spotlight-38513'
    },
    countdownInProgressText(): TranslateResult {
      return this.unitedPrayer ? `${this.$t('now praying')} ...` : ''
    },
    countdownTo(): number | undefined {
      const countdown = this.feature?.countdownTo
      return countdown ? countdown * 1000 : undefined
    },
    countdownDuration(): number {
      return this.unitedPrayer ? 3600000 : 0
    },
    countdownEnd(): number | undefined {
      return (this.countdownTo ?? 0) + this.countdownDuration
    },
    feature(): DisplayAdApi | undefined {
      if (!this.features.length) return undefined
      return this.features[this.activeIndex]
    },
    spotlightLink(): FeatureLink | undefined {
      return this.feature ? featureLink(this.feature) : undefined
    },
    spotlightTitle(): string {
      return this.feature?.title ?? ''
    },
    spotlightText(): string {
      return this.feature?.description ?? ''
    },
    featureImage(): string {
      const images = this.feature?.images
      if (!images?.length) return ''
      return randomInArray(images).imageURLWebp
    },
  },
  methods: {
    nextSpotlight() {
      let next = this.activeIndex + 1
      if (next > this.features.length - 1) {
        next = 0
      }
      this.activeIndex = next
    },
    prevSpotlight() {
      let prev = this.activeIndex - 1
      if (prev < 0) {
        prev = this.features.length - 1
      }
      this.activeIndex = prev
    },
  },
})
